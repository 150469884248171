.search-compact .ant-select-selector,
.search-compact .ant-input-group-addon,
.search-compact .ant-input-outlined {
  background: #fff !important;
}

.auth-btns .bg-white {
  background: #fff !important;
}

.auth-wrapper .ant-input,
.auth-wrapper .ant-input-affix-wrapper {
}

.auth-wrapper .custom-form-item {
  margin-bottom: 0 !important;
}

.auth-wrapper .custom-radio-group {
  font-size: inherit !important;
  display: flex !important;
}

.auth-wrapper {
}

.auth-content {
  max-height: calc(100vh - 52px);
}

.auth-background {
  background: linear-gradient(0deg, #ffffff 0%, #ffff 100%);
}

.main-content {
  height: calc(100vh - 132px);
}

.main-public {
  height: calc(100vh - 132px);
}

.main-public-sidebar {
  height: calc(100vh - 218px);
}

.modal-create-user .ant-modal-content {
  padding: 0 !important;
  border-radius: 0 !important;
}

.modal-create-user .ant-modal-header {
  border-bottom: 2px solid transparent;
  position: relative;
  padding: 15px 32px !important;
}

.modal-create-user .ant-modal-header:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #d9d9d9;
  width: 100%;
  height: 1px;
}

.modal-create-user .ant-modal-body {
  padding: 15px 32px !important;
}

.ant-form-vertical .ant-form-item-required::before {
  visibility: hidden !important;
  display: none !important;
  margin-inline-end: 0 !important;
  font-size: 16px !important;
  font-family: SimSun, sans-serif !important;
  line-height: 1 !important;
  content: '' !important;
}

.ant-form-vertical .ant-form-item-required::after {
  display: inline-block !important;
  margin-inline-start: 4px !important;
  color: #f33b3b !important;
  font-size: 16px !important;
  font-family: SimSun, sans-serif !important;
  line-height: 1 !important;
  content: '*' !important;
  visibility: visible !important;
}

.custom-ribbon .ant-ribbon-text {
  margin-inline-end: 8px;
}

.custom-list-item-meta .ant-list-item-meta-title {
  margin-block-end: 0 !important;
  font-size: 14px !important;
}

.custom-tabs > .ant-tabs-nav {
  margin: 0 !important;
}

.avatar-uploader > .ant-upload.ant-upload-select {
  width: 100% !important;
  height: 100% !important;
}

.avatar-info > .ant-avatar-string {
  transform: scale(1) !important;
}

.avatar-info > .ant-avatar-string .ant-image {
  display: block !important;
}
.ant-picker-panels {
  @apply flex-col md:flex-row;
}
